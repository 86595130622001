import WebServiceRequest from "../Api/WebServiceRequest";

class GetAllFormInputs extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("FormInput/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export { GetAllFormInputs };
