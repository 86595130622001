import WebServiceRequest from "../Api/WebServiceRequest";

class CreateNewLang extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Language/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetAllLangs extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Language/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class DeleteALanguage extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Language/DeleteBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateALanguage extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Language/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}
export { CreateNewLang, GetAllLangs, DeleteALanguage, UpdateALanguage };
