import WebServiceRequest from "../Api/WebServiceRequest";

class CreateNewPage extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Page/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetAllPages extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Page/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class DeleteAPage extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Page/DeleteBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class GetASinglePage extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Page/GetBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateASinglePage extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Page/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export {
  CreateNewPage,
  GetAllPages,
  DeleteAPage,
  GetASinglePage,
  UpdateASinglePage,
};
