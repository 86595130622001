<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-row>
      <b-col cols="12">
        <div class="w-100 d-flex align-items-center" style="gap: 1rem">
          <b-form class="w-100" @submit.prevent>
            <b-form-group :label="placeholder" label-for="form-input-picker">
              <v-select
                @search="handleSearch"
                id="form-input-picker"
                dir="rtl"
                :filterable="false"
                v-if="formInputList"
                v-model="selectedFormInput"
                :options="formInputList"
                placeholder="نوع فرم را انتخاب کنید"
              >
                <template slot="option" slot-scope="option">
                  {{ inputTypes[option.type] }}
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ inputTypes[option.type] }}
                </template>
                <template #no-options
                  >هیچ نوع فرمی جهت نمایش وجود ندارد</template
                >
              </v-select>
            </b-form-group>
          </b-form>
          <b-button
            v-if="isRenderingButton"
            style="margin-top: 1px"
            variant="primary"
            @click="emitSelectedForm"
          >
            افزودن
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { GetAllFormInputs } from "@/libs/Api/FormInput";
import vSelect from "vue-select";
import {
  BButton,
  BOverlay,
  BRow,
  BCol,
  BForm,
  BFormGroup,
} from "bootstrap-vue";

export default {
  emits: ["getFormInput"],
  props: {
    defaultFormInputId: {
      type: [String, Number],
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "انتخاب نوع فرم مورد نظر",
    },
    isRenderingButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: false,
      formInputList: null,
      searchCommand: null,
      timeout: null,
      selectedFormInput: null,
      inputTypes: {
        CustomFile: "فایل",
        CustomTextarea: "متن بزرگ",
        CustomText: "متن",
        CustomSelect: "لیست",
        CustomRadio: "رادیو",
        CustomCheckbox: "چند گزینه‌ای",
      },
    };
  },
  async mounted() {
    await this.getAllFormInputs();
    if (this.defaultFormInputId) {
      let selectedFormInput = this.formInputList.find(
        (item) => item.formInputId == this.defaultFormInputId
      );
      if (selectedFormInput) {
        this.selectedFormInput = selectedFormInput;
      }
    }
  },
  watch: {
    selectedFormInput: {
      handler(val) {
        if (!this.isRenderingButton) {
          this.$emit("getFormInput", val);
        }
      },
    },
  },
  methods: {
    emitSelectedForm() {
      this.$emit("getFormInput", this.selectedFormInput);
    },
    handleSearch(searchValue, loadingFn) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.searchCommand = searchValue;
        loadingFn(true);
        await this.getAllFormInputs();
        loadingFn(false);
      }, 300);
    },
    async getAllFormInputs() {
      try {
        this.isLoading = true;
        let reqParams = {
          count: 0,
          pageNumber: 0,
        };
        if (this.searchCommand && this.searchCommand != "") {
          reqParams["searchCommand"] = this.searchCommand;
        }
        let _this = this;
        let getAllFormInputs = new GetAllFormInputs(_this);
        getAllFormInputs.setRequestParam(reqParams);
        await getAllFormInputs.fetch((response) => {
          if (response.isSuccess) {
            _this.formInputList = response.data.forms;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    vSelect,
    BOverlay,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
  },
};
</script>
